export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Utama',
      disabled: true,

      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Kedua',
      disabled: true,

      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    p1: {
      label: 'Dimulai Tanggal',
      widget: 'wdatenormal',
      col: 6,
      data: []
    },
    p2: {
      label: 'Dimulai Jam',
      widget: 'wtext',
      col: 6,
      data: []
    },
    p3: {
      label: 'Keluhan Utama (mulai, lama, pencetus) : ',
      widget: 'wtextarea',
      col: 6,
      data: []
    },
    p4: {
      label: 'Riwayat Penyakit Sekarang : ',
      widget: 'wtextarea',
      col: 6,
      data: []
    },
    p5: {
      label: 'Riwayat Penyakit Keluarga : ',
      widget: 'wradio',
      data: [
        { text: 'DM', value: 'DM' },
        { text: 'Hipertensi', value: 'Hipertensi' },
        { text: 'TBC', value: 'TBC' },
        { text: 'Asthma', value: 'Asthma' },
        { text: 'Hepatitis', value: 'Hepatitis' },
        { text: 'Jantung', value: 'Jantung' },
        { text: 'Kelaianan Darah Keluarga', value: 'Kelaianan Darah Keluarga' },
        { text: 'TAK', value: 'TAK' },
        { text: 'Lain-Lain', value: null, field: 'auto' }
      ],
      col: 6
    },
    p6: {
      label: 'Riwayat Alergi : ',
      widget: 'wradio',
      data: [
        { text: 'A. Obat', value: 'A. Obat' },
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ya Sebutkan : ', value: null, field: 'auto' }
      ],
      col: 6
    }
  },
  form2: {
    p1: {
      label: '1. Keadaan Umum',
      widget: 'wradio',
      data: [
        { text: 'Tidak Tampak Sakit', value: 'Tidak Tampak Sakit' },
        { text: 'Tampak Sakit Ringan', value: 'Tampak Sakit Ringan' },
        { text: 'Tampak Sakit Sedang', value: 'Tampak Sakit Sedang' },
        { text: 'Tampak Sakit Berat', value: 'Tampak Sakit Berat' }
      ],
      col: 6
    },
    p2: {
      label: '2. Kesadaran',
      widget: 'wradio',
      data: [
        { text: 'Alert', value: 'Alert' },
        { text: 'Verbal', value: 'Verbal' },
        { text: 'Pain', value: 'Pain' },
        { text: 'Unrespon', value: 'Unrespon' }
      ],
      col: 6
    },
    p3: {
      label: 'GCS',
      widget: 'wtext',
      col: 12,
      data: []
    },
    l1: {
      label: 'TTV',
      widget: 'wlabelkiri',
      col: 12,
      data: []
    },
    p4: {
      label: 'S',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p5: {
      label: 'N',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p6: {
      label: 'RR',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p7: {
      label: 'SpO2',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p8: {
      label: 'TD',
      widget: 'wtext',
      col: 3,
      data: []
    },
    p9: {
      label: 'Down Score',
      widget: 'wtext',
      col: 4,
      data: []
    },
    p10: {
      label: 'A/S',
      widget: 'wtext',
      col: 4,
      data: []
    },
    p11: {
      label: 'Pemeriksaan Status Generalis',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p12: {
      label: 'Pemeriksaan Status Lokalis',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p13: {
      label: 'Pemeriksaan Penunjang Pre-Rawat Inap',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p14: {
      label: 'Diagnosis Kerja',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p15: {
      label: 'Diagnosa Banding',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p16: {
      label: 'Pemeriksaan Status Lokalis',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    p17: {
      label: 'Perencanaan Pelayanan Penatalaksanaan/Pengobatan :',
      widget: 'wtextarea',
      col: 12,
      data: []
    }
  }
}
